<app-advanced-input [(ngModel)]="value"
                    (ngModelChange)="propagateChange($event)"
                    type="select"
                    [asyncOptionsAPI]="asyncOptionsAPI"
                    [bindValue]="bindValue"
                    [bindLabel]="bindLabel"
                    icon="user"
                    placeholder="Patient"
                    [class]="classes"
                    [searchTermMinLength]="searchTermMinLength">
    <ng-template ng-label-tmp let-patient="item">
        {{patient|humanName}}
    </ng-template>
    <ng-template ng-option-tmp let-patient="item">
        <div class="flex-grow-1" style="font-size: 1rem;">
            <div class="d-flex align-items-center justify-content-between">
                <div class="d-flex align-items-center">
                    <span class="o-hidden">{{patient|humanName}}</span>
                    <app-bst-badge *ngIf="patient.isBst"
                                   [view]="'mini'"
                                   ngbTooltip="BroadStreet record"
                                   class="ml-2">
                    </app-bst-badge>
                </div>
                <div *ngIf="patient.censusStatus"
                     class="small ml-1 d-flex align-items-center">
                    <span class="status-indicator mr-1 bg-{{patient.censusStatus.color}}"></span> {{patient.censusStatus.status}}
                </div>
            </div>
            <div class="d-flex align-items-center small">
                <span class="text-muted mr-3">{{patient.date_ob|date}}</span>
                <span class="text-muted ml-auto">{{patient.facility|facility:'name'}}</span>
            </div>
        </div>
    </ng-template>
</app-advanced-input>
