import {Component, forwardRef, Input} from '@angular/core';
import {UnsubscribeComponent} from '../../../../@core/fc-component';
import {OptionsAPIOptions} from '../../../../@core/forms/form-field-select';
import {APIService, PatientCensusViewSetQueryParams, PatientListSerializer} from '../../../../@core/api.service';
import {Human} from '../../../../models/human';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {Patient} from '../../../../models/patient';
import {map} from 'rxjs/operators';
import {CensusEvent} from '../../../../models/census-event';

@Component({
    selector: 'app-patient-select',
    templateUrl: './patient-select.component.html',
    styleUrls: ['./patient-select.component.scss'],
    providers: [
        {provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => PatientSelectComponent), multi: true},
    ],
})
export class PatientSelectComponent extends UnsubscribeComponent implements ControlValueAccessor {
    @Input() filters: PatientCensusViewSetQueryParams;
    @Input() facility__in: number[];
    @Input() bindValue = 'id';
    @Input() bindLabel = 'name';
    @Input() viewSet = APIService.PatientCensusViewSet;
    @Input() searchTermMinLength = 0;
    @Input() excludeBst = false;
    @Input() excludeRelatedPatient: number;
    @Input() classes = 'body-select';

    value;

    asyncOptionsAPI: OptionsAPIOptions = {
        viewSet: {
            ...this.viewSet,
            list: filters => {
                if (this.filters) filters = {...filters, ...this.filters};
                if (this.facility__in) filters = {...filters, facility__in: this.facility__in};
                if (this.excludeBst) filters = {...filters, exclude_origin: ['BST']};
                if (this.excludeRelatedPatient) filters = {...filters, exclude_related_patients: this.excludeRelatedPatient};
                return this.viewSet.list(filters).pipe(map(x => x.map(y => (
                    {
                        ...y,
                        isBst: Patient.isBst(y),
                        censusStatus: y.census_status && {
                            status: CensusEvent.statusText(y.census_status),
                            color: CensusEvent.colorClass(CensusEvent.occupancyStatus(y.census_status)),
                        },
                    }
                ))));
            },
        },
        nameFunction: (x: PatientListSerializer) => `${Human.getName(x)} (${x.mrid})`,
        valueFunction: x => x,
    };

    writeValue(val): void {
        this.value = val;
    }

    propagateChange = (_: any) => {
    };

    propagateTouch = (_: any) => {
    };

    registerOnChange(fn) {
        this.propagateChange = fn;
    }

    registerOnTouched(fn) {
        this.propagateTouch = fn;
    }
}
